import store from 'store2'
import ky from 'ky'
import dayjs from 'dayjs'

export const apiHost = process.env.VUE_APP_BASE_URI

const _getHeaders = () => {
    return {
        'Authorization': `Bearer ${store('apiToken')}`,
        'Content-Type': 'application/json',
    }
}

const _getUrl = (endpoint) => {
    return `${apiHost}${endpoint}`
}

export const login = async (username, password) => {
    const endpoint = _getUrl('/auth/authenticate')
    const payload = {
        username,
        password,
    }
    const resp = await ky.post(endpoint, { json: payload }).json()
    // resp will be empty (204) if the credentials don't match
    // or the token in a json response if they do
    if (!resp) {
        store('apiToken', '')
        return false
    }
    else {
        store('apiToken', resp.token)
        return true
    }
}

export const getLoginNextStep = async (username) => {
    const endpoint = _getUrl('/auth/next-step')
    const resp = await ky.get(endpoint, {
        searchParams: {
            email: username
        }
    }).json()
    return resp['nextStep']
}

export const getMembership = async () => {
    const endpoint = _getUrl('/membership/')
    const resp = await ky.get(endpoint, { headers: _getHeaders() }).json()
    return resp
}

function _convertChartDates(charts) {
    return charts.map(chart => {
        return {
            ...chart,
            dateOfBirth: dayjs.unix(chart.dateOfBirth),
        }
    })
}

export const getMemberCharts = async () => {
    const endpoint = _getUrl('/charts/')
    const resp = await ky.get(endpoint, {
        headers: _getHeaders(),
        searchParams: {
            visibility: ['members', 'public'].join(','),
        }
    }).json()
    return _convertChartDates(resp.charts)
}

export const getPublicCharts = async () => {
    const endpoint = _getUrl('/charts/')
    const resp = await ky.get(endpoint, {
        headers: _getHeaders(),
        searchParams: {
            visibility: 'public',
        }
    }).json()
    return _convertChartDates(resp.charts)
}

export const signOut = () => {
    store('apiToken', '')
    // TODO: API call shouldn't do this...
    location.href = '/'
}

export const isAuthenticated = () => {
    return store('apiToken') !== ''
}

export const changePassword = async (username, oldPassword, newPassword, confirmPassword) => {
    const endpoint = _getUrl('/auth/change-password')
    const payload = {
        username,
        oldPassword,
        newPassword,
        confirmPassword
    }
    
    try {
        const resp = await ky.post(endpoint, { 
            json: payload,
            throwHttpErrors: false // This allows us to handle all response types
        }).json()
        if (resp.status === 200) {
            store('apiToken', resp.token)
        }
        return resp || { status: 500, detail: "An unidentified server error occurred" }
    } catch (error) {
        return { status: 500, detail: "An unidentified server error occurred" }
    }
}

export const createPassword = async (username, newPassword, confirmPassword) => {
    const endpoint = _getUrl('/auth/create-password')
    const payload = {
        username,
        newPassword,
        confirmPassword
    }
    
    try {
        const resp = await ky.post(endpoint, { 
            json: payload,
            throwHttpErrors: false
        }).json()
        if (resp.status === 200) {
            store('apiToken', resp.token)
        }
        return resp || { status: 500, detail: "An unidentified server error occurred" }
    } catch (error) {
        return { status: 500, detail: "An unidentified server error occurred" }
    }
}
import { createWebHashHistory, createRouter } from 'vue-router'
import { isAuthenticated } from '@/lib/api'

const Login = () => import('@/views/LoginView.vue')
const Dashboard = () => import('@/views/DashboardView.vue')
const Numeracheck = () => import('@/views/NumeracheckView.vue')
const PersonalYearMonthDay = () => import('@/views/PersonalYearMonthDayView.vue')
const TableOfEvents = () => import('@/views/TableOfEventsView.vue')
const StarterBirthPathNumber = () => import('@/views/StarterBirthPathNumber.vue')

const routes = [
    { path: '/', name: 'login', component: Login, meta: { requiresAuth: false } },
    { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true } },
    { path: '/numeracheck', name: 'coreCharts', component: Numeracheck, meta: { requiresAuth: true } },
    { path: '/pymd', name: 'personalYearNumber', component: PersonalYearMonthDay, meta: { requiresAuth: true } },
    { path: '/toe', name: 'tableOfEvents', component: TableOfEvents, meta: { requiresAuth: true } },
    { path: '/starter-kit', name: 'starterKit', component: StarterBirthPathNumber, meta: { requiresAuth: true } },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})
<template>
  <HeaderPanel />
  <router-view></router-view>
</template>

<script setup>
import { provide, ref } from 'vue'
import { RouterView } from 'vue-router'

import HeaderPanel from '@/components/HeaderPanel.vue'

const token = ref('')
provide('token', token)
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
}

:root {
  --color-blue-navy: #01193E;
  --color-gold: #CEAB5C;
  --color-white-off: #EAE0D5;
  --color-brown-dark: #553300;
  --color-brown-mid: #A47A4D;
  --color-brown-red: #5B2231;
  --color-sand: #FFF7E6;
  --color-sand-dark: #F9EAC7;
  --color-sand-highlight: #F3D591;

  --color-shadow: rgba(0, 0, 0, 0.8);

  --color-input-valid-bg: #00B300; // lighter green
  --color-input-valid-border: #008000; // darker green
  --color-input-invalid-bg: #FF3333; // lighter red
  --color-input-invalid-border: #990000; // darker red

  --card-radius: 1rem;
  --card-bg-color: var(--color-sand-dark);
  --card-padding: 1em 1em 1.3em 1em;

  --color-divider: var(--color-gold);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  font-weight: normal;
}

body {
  background-color: var(--color-sand);
  color: var(--color-brown-dark);
  padding-bottom: 8rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

#app {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-weight: 800;
  font-size: 1.8rem;
  margin-top: 0;
  padding-top: 0;
}

h2 {
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 0;
  padding-top: 0;
}

h3 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 0;
  padding-top: 0;
}

input {
  height: 2.5em;
  border-radius: 0.5em;
  border: 2px solid var(--color-brown-dark);
  padding: 0.5em;
  font-size: 1em;
  color: var(--color-brown-dark);

  &:focus {
    outline: none;
    border-color: var(--color-gold);
  }

  &::placeholder {
    color: var(--clr-brown-dark);
  }
}

input[type="date"] {
  font-family: 'Montserrat', sans-serif;
  color: var(--color-brown-dark);
  -webkit-text-fill-color: var(--color-brown-dark);
}

select {
  height: 2.5em;
  border-radius: 0.5em;
  border: 2px solid var(--color-brown-dark);
  padding: 0.5em;
  font-size: 1em;
  color: var(--color-brown-dark);
  width: auto !important;
  cursor: pointer;

  // improve and make consistent the arrow
  padding-right: 1.7rem; /* Add some right padding to make space for the arrow */
  appearance: none; /* Remove the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  // background image sets the color of the arrow, you have to encode # to %23 in the data
  // it is set to --color-brown-dark
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23553300"><polygon points="0,0 20,0 10,10"/></svg>');
  background-repeat: no-repeat;
  background-position: right 6px top 0.9rem; /* Position the arrow with some right padding */
  background-size: 12px; /* Set the size of the arrow */

  &:focus {
    outline: none;
    border-color: var(--color-gold);
  }
}

.valid,
.correct {
  background-color: var(--color-input-valid-bg);
  border-color: var(--color-input-valid-border);
  color: white;
  font-weight: bold;
}

.invalid,
.incorrect {
  background-color: var(--color-input-invalid-bg);
  border-color: var(--color-input-invalid-border);
  color: white;
  font-weight: bold;
}

button {
  border-radius: 0.8rem;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1em;
  cursor: pointer;
  box-shadow: 0px 12px 24px -12px var(--color-shadow);

  &.primary {
    background-color: var(--color-blue-navy);
    color: var(--color-white-off);

    &:hover {
      background-color: var(--color-brown-red);
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &.secondary {
    color: var(--color-blue-navy);
    background-color: #EAEAEA;
    margin-left: 2.5em;

    &:hover {
      background-color: var(--color-brown-red);
      color: var(--color-white-off);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.card {
  border-radius: var(--card-radius);
  background-color: var(--card-bg-color);
  padding: var(--card-padding);
}

@media print {
  .noprint {
    display: none !important;
  }
}
</style>

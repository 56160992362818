import { router } from '@/router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons'

import pkg from '../package.json'
function generateBuildInfo() {
  const buildInfo = {
    productName: 'Soul Weaving Numerology App',
  }
  const pkgKeys = ['author', 'license', 'version']
  for (const k of pkgKeys) {
    buildInfo[k] = pkg[k]
  }
  return buildInfo
}
const buildInfo = generateBuildInfo()
console.log(`${buildInfo.productName} © 2025 ${buildInfo.author}. All rights reserved.`)
console.log(`Version: ${buildInfo.version}`)


import { createApp } from 'vue'
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false
import App from './App.vue'

library.add(faCircleArrowLeft)

createApp(App)
  .use(router)
  .mount('#app')

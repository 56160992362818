<template>
    <header
      :style="bgStyles"
      class="header-panel"
    >
        <font-awesome-icon
          v-if="!isOnDashboard"
          icon="fa-solid fa-circle-arrow-left"
          class="back-button"
          @click="goTo('dashboard')"
        />
        <a @click="goTo('dashboard')"><img class="logo" :src="`${publicPath}logo-high-res-trans-tag.svg`"></a>
    </header>
</template>

<script setup>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useRoute } from 'vue-router'

const route = useRoute()
const isOnDashboard = computed(() => route.name === 'dashboard')

const publicPath = process.env.BASE_URL

import { router } from '@/router'



function goTo(routeName) {
  router.push({ name: routeName })
}

const bgStyles = computed(() => {
  return { 'background-image': `url(${publicPath}stars-background-static.jpg)`}
})
</script>

<style lang="scss" scoped>
.header-panel {
  max-width: 800px;
  border-radius: 0 0 0.5em 0.5em;
  margin-bottom: 1em;
  margin-top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 12rem;
  background-size: cover; /* Scale the background image to cover the element */
  background-position: center; /* Center the background image */
  position: relative;
}

.logo {
  display: block;
  width: 80%;
  margin-left: 10%;
  max-height: 8rem;
}

a {
  cursor: pointer;
}

.back-button {
  position: absolute;
  left: 2rem;
  font-size: 2rem;
  color: white;
  opacity: 0.5;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 0.5rem;

  &:hover {
    transform: scale(1.1);
  }
}

@media (max-width: 800px) {
  .header-panel {
    border-radius: 0;
    margin-top: 0;
  }
}
</style>